<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">סימון כשולם</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogReportPaid__Component__Loading">
          <b-field
            label="מספר אסמכתה"
            :type="!isValidReference ? 'is-danger' : ''"
            :message="!isValidReference ? 'יש להזין מספר אסמכתה' : ''"
          >
            <b-input v-model="reference"></b-input>
          </b-field>
          <b-field
            label="תאריך תשלום"
            :type="!isValidDate ? 'is-danger' : ''"
            :message="!isValidDate ? 'יש להזין תאריך' : ''"
          >
            <b-datepicker
              v-model="date"
              class="datetime"
              placeholder="לחץ כדי לבחור"
              icon-prev="chevron-right"
              icon-next="chevron-left"
              icon="calendar-today"
              locale="en-GB"
              :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']"
              :month-names="[
                'ינואר',
                'פברואר',
                'מרץ',
                'אפריל',
                'מאי',
                'יוני',
                'יולי',
                'אוגוסט',
                'ספטמבר',
                'אוקטובר',
                'נובמבר',
                'דצמבר',
              ]"
              close-on-click
              editable
              append-to-body
            >
            </b-datepicker>
          </b-field>
          <b-field
            label="בחר שעה"
            :type="!isValidHour ? 'is-danger' : ''"
            :message="!isValidHour ? 'יש להזין שעה' : ''"
          >
            <b-timepicker
              v-model="hour"
              class="datetime"
              placeholder="לחץ כדי לבחור"
              icon="clock"
              editable
              hour-format="24"
              append-to-body
              locale="en-GB"
            >
            </b-timepicker>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :loading="isLoading"
          @click="save"
          label="שמור"
          type="is-primary"
        />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import ReportService from "@/services/ReportService";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "DialogReportPaid",
  props: ["reports", "onSuccess"],
  computed: {
    isValidReference() {
      if (this.submitted) {
        return this.reference;
      }
      return true;
    },
    isValidDate() {
      if (this.submitted) {
        return this.date;
      }
      return true;
    },
    isValidHour() {
      if (this.submitted) {
        return this.hour;
      }
      return true;
    },
  },
  data() {
    return {
      isLoading: false,
      submitted: false,
      reference: null,
      date: new Date(),
      hour: new Date(),
    };
  },
  methods: {
    save() {
      this.submitted = true;
      if (this.isValidReference && this.isValidDate && this.isValidHour) {
        this.isLoading = true;
        const reqs = this.reports.map((r) =>
          ReportService.setAsPaid([
            {
              ReportId: r,
              PaymentDate: this.date,
              PaymentReferenceNumber: this.reference,
            },
          ])
        );
        Promise.all(reqs)
          .then(() => {
            this.$emit("close");
            if (this.onSuccess) this.onSuccess();
            Toast.open({
              type: "is-success",
              message: "הפעולה בוצעה!",
              duration: 4000,
            });
          })
          .catch(() => {
            Toast.open({
              type: "is-danger",
              message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
              duration: 8000,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.datetime .dropdown-content {
  width: 25%;

  .field-body .field {
    flex-direction: row-reverse;
  }
}
</style>
